.chat {
    height: 100%;
    display: flex;
    flex-direction: column;

    .header {
        display: flex;
        justify-content: space-between;
    }

    .session-list {
        width: 100%;
    }
    
    .choice-list {
        width: 100%;
        height: 100%;
        overflow: auto;
        .user {
            font-size: 14px;
            display: flex;
            flex-direction: row-reverse;

            span {
                display: inline-block;
                background-color: #07C160;
                padding: 2px 5px;
                border-radius: 10px;
                max-width: calc(100% - 50px);
            }

            label {
                margin-left: 3px;
                background-color: #07C160;
                border-radius: 50%;
                display: inline-block;
                width: 30px;
                height: 30px;
                line-height: 30px;
                text-align: center;
            }
        }
    
        .assistant {
            font-size: 14px;
            display: flex;

            span {
                display: inline-block;
                background-color: #ccc;
                padding: 2px 5px;
                border-radius: 10px;
                max-width: calc(100% - 50px);
            }

            label {
                margin-right: 3px;
                margin-left: 2px;
                background-color: #ccc;
                border-radius: 50%;
                display: inline-block;
                width: 30px;
                height: 30px;
                line-height: 30px;
                text-align: center;
            }
        }

        .adm-list-item, .adm-list-item-content {
            padding: 0;
        }
    }

    .action {
        width: 100%;
        margin: 0 2px;
        margin-bottom: 2px;
        display: flex;
        height: 30px;

        .button {
            width: 60px;
        }
    }
}
