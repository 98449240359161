.login {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    height: 100vh;
    padding: 0 5px;

    .button {
        margin-top: 10px;
    }
}